import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import cartReducer from './cart';

const createStore = () => configureStore({
    reducer: {
        user: userReducer,
        cart: cartReducer,
    },
})

export default createStore