import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '@src/utils/mmc-api/api';


export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    miniCart: [],
    ownedProducts: []
  },
  reducers: {
    addToCart: (state, action) => {
      state.miniCart.push(parseInt(action.payload));
      state.miniCart = [...new Set(state.miniCart)];
    },
    removeFromCart: (state, action) => {
      let set = new Set(state.miniCart);
      set.delete(parseInt(action.payload));
      state.miniCart = [...set];
    },
    clearCart: (state, action) => {
      state.miniCart = [];
    },
    setCart: (state, action) => {

      state.miniCart = [...new Set(action.payload?.cart)];
    },
    setOwned: (state, action) => {
      state.ownedProducts = [...new Set(action.payload?.ownedProducts)];
    }
  },
});

export default cartSlice.reducer;

export const addToCart = (id, user) => async (dispatch) => {
  dispatch(cartSlice.actions.addToCart(id));
  let localCart = [];
  if (localStorage.getItem('cart') && JSON.parse(localStorage.getItem('cart'))?.length > 0) {
    localCart = [...JSON.parse(localStorage.getItem('cart'))];
  }

  localCart.push(parseInt(id));
  localCart = [...new Set(localCart)];
  localStorage.setItem('cart', JSON.stringify(localCart));

  if (user && user?.email) {
    const cart = await api.addToCart(id);
    dispatch(cartSlice.actions.setCart(cart));

  } else {

    dispatch(cartSlice.actions.setCart({cart : localCart}));

  }
};

export const removeFromCart = (id, user) => async (dispatch) => {
  dispatch(cartSlice.actions.removeFromCart(id));
  let cart;
  if (user?.email) {
    cart = await api.removeFromCart(id);
  } else {
    let set = new Set(JSON.parse(localStorage.getItem('cart')));
      set.delete(parseInt(id));
      cart = [...set];
    localStorage.setItem('cart', JSON.stringify(cart));
  }
  dispatch(cartSlice.actions.setCart(cart));
};

export const clearCart = () => async (dispatch) => {
  dispatch(cartSlice.actions.clearCart());
  localStorage.removeItem('cart');
  const cart = await api.clearCart();
  dispatch(cartSlice.actions.setCart(cart));
};

export const fetchMiniCart = (user) => async (dispatch) => {
  const localCart = localStorage.getItem('cart');

  if (localCart === "null" || localCart?.length < 4) { localStorage.removeItem('cart')}
  let cart;
  if (user && user?.email) {
    cart = await api.miniCart();
  } else {
    if (localStorage.getItem('cart') !== null && localStorage.getItem('cart') !== undefined) {
      if (JSON.parse(localStorage.getItem('cart'))?.length > 0) {
        cart = JSON.parse(localStorage.getItem('cart'));

      }
    }     
  }

  dispatch(cartSlice.actions.setCart(cart));
  dispatch(cartSlice.actions.setOwned(cart));
};
