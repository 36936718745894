import React, { useEffect, useState } from 'react';
import { Provider } from "react-redux"
import { ApolloProvider, ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'isomorphic-fetch';
import createStore from '@src/store';
import { fetchUser } from '@src/store/user';
import { Messenger } from '@src/components/Messenger.js';

import './src/assets/styles/index.scss';
import './src/assets/styles/global.css';

const datoCMS = new HttpLink({
  uri: process.env.GATSBY_DATO_CMS_URL,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${process.env.GATSBY_DATO_API_TOKEN}`,
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(datoCMS)
});

export const wrapRootElement = ({ element }) => {
  const store = createStore();
  store.dispatch(fetchUser());

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        {element}
        <Messenger />
      </ApolloProvider>
    </Provider>
  );
};
