import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '@src/utils/mmc-api/api';

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const user = await api.me();
  return user;
});

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: null,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.data = action.payload;
      })
  }
})

export default userSlice.reducer